import React, { useEffect, useState } from 'react';
import { NavLink, Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import moment from 'moment';
import { Form, Button } from 'react-bootstrap';
import { setLoader, getAllCollection, getExplore, addRemoveFavItems } from "../../store/actions/Auth";

import './index.css';
const collectionImage = "/images/collection-img.png";
const alternate = "/images/alternate.jpg";
const bag = "/images/bag.png";
const bagWhite = "/images/bag-white.png";
const like = "/images/likes.svg";

const Drops = (props) => {

    const [status, setStatus] = useState();
    const [itemPerRow, setItemPerRow] = useState(10);
    const [next, setNext] = useState(itemPerRow);
    const [setFound, setNotFound] = useState(false);

    const [items, setItems] = useState([]);

    useEffect(() => {
        props.setLoader({message:"Load NFT's...",status:true});
        props.getExplore({ chain: props.chain });
    }, [props.chain]);

    useEffect(() => {
        if (props.explore.length > 0) setItems(props.explore);
    }, [props.explore]);

    const handleMoreImage = () => {
        setNext(next + itemPerRow);
    };

    const handleImageError = (idx) => {
        items[idx]['image'] = alternate;
        setItems([...items]);
    };

    const handleUserImageError = (idx) => {
        items[idx]['users']['image'] = alternate;
        setItems([...items]);
    };

    return (

            <div class="picks selling">
              <h1>NFT's minted</h1>
                <div className="row">
                {
                    !setFound ?
                        items && items.length > 0 && items.slice(0, next).map((item, idx) =>
                      <div className="col-xl-4 col-sm-6">
                          <div className="collection-box">
                            <div className="img-wrap">
                                {/* <img src={collectionImage} alt="Alternate Image" /> */}
                                <img key={idx} src={ item && item['image'] ? item['image'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />

                                <Link to={`/home/itemdetail/${item['_id']}`} className="common-btn white">
                                                                <img src={bag} alt="" />
                                                                <img className="white" src={bagWhite} alt="" />
                                                                Detail
                                </Link>
                                <div className="likes">
                                    <img src={like} />{item && item['totalFav'] ? item['totalFav'] : 0}
                                </div>
                            </div>

                            <div className="content">
                              <div className="collection-content-head">
                                  <h3>{item && item['title'] ? item['title'] : "-"}</h3>

                                  {/* <div className="protocol">
                                      Minting
                                  </div> */}
                              </div>
                          

                              <div className="creator-wrap">
                                  <div className="left">
                                  {item && item.tokenType == "erc1155" ?
                                        <h4>
                                            Mint Amount  {item && item.mintAmount}
                                        </h4>
                                        :
                                        <h4>
                                        {/* <img src={history} alt="" /> */}
                                            Token Id {item && item.nftId}
                                        </h4>
                                        }
                               
                                  </div>

                                  <div className="bid-price">
                                  <div className="left">
                                    <span>Price </span>
                                  <h4>{item && item.price}
                                        {item && item.chain == 11155111 && " ETH"}
                                        {item && item.chain == 1 && " ETH"}
                                        {item && item.chain == 56 && " BSC"}
                                        {item && item.chain == 97 && " BSC"}
                                        {item && item.chain == 50 && " XDC"}
                                        {item && item.chain == 51 && " XDC"}
                                        {item && item.chain == 43114 && " AVAX"}
                                        {item && item.chain == 43113 && " AVAX"}
                                    </h4>
                                  </div>
                                    
                              </div>
                              </div>
                            </div>
                          </div>
                      </div>
                       ) :
                       <div className="text-center">
                           <h2> Not Found </h2>
                       </div>
               }
                </div>
                <div className="mt-4 text-center">
                        {next < items.length && (
                        <a className="common-btn border-white" onClick={handleMoreImage}>
                            Load More
                        </a>
                        )}
                </div>
            </div>
    );
}

const mapDispatchToProps = { setLoader, getAllCollection, getExplore, addRemoveFavItems };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, chain, publicAddress, singleCollectionDetails, collections, explore } = Auth;
    return { isLogin, chain, publicAddress, singleCollectionDetails, collections, explore }
}

export default connect(mapStateToProps, mapDispatchToProps)(Drops);
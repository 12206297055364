import React, { useEffect, useState } from 'react';
import { NavLink, Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import { setLoader, getAllNfts, getAuctions, addRemoveFavItems, getAllCollection } from "../../store/actions/Auth";
import "./index.css";

const collectionImage = "/images/collection-img.png";
const bag = "/images/bag.png";
const bagWhite = "/images/bag-white.png";
const like = "/images/likes.svg";
const alternate = "/images/alternate.jpg";

const MyCollection = (props) => {
    const [collections, setCollections] = useState([]);
    const [itemPerRow, setItemPerRow] = useState(10);
    const [next, setNext] = useState(itemPerRow);

    useEffect(() => {
        props.getAllCollection({chain: props.chain});
    }, [props.chain]);

    useEffect(()=>{
        if(props.collections.length > 0) setCollections(props.collections);
    },[props.collections])

    const handleCollectionImageError = (idx) => {
        collections[idx]['profileImage'] = alternate;
        setCollections([...collections]);
    }

    const handleCollectionUserImageError = (idx) => {
        collections[idx]['users']['image'] = alternate;
        setCollections([...collections]);
    }

    const handleMoreImage = () => {
        setNext(next + itemPerRow);
    };


    return (
      <div class="picks">
        <h1>My Collections</h1>
          {collections.length > 0 &&
            <>
            <div className="row">
                {collections.slice(0, next).map((item, idx)=>
                <Link to={`/singlecollection/${item['chain']}/${item['tokenAddress']}`} className="col-xl-4 col-sm-6">
                    <div className="collection-box">
                        <div className="img-wrap">  
                            <img key={idx}  src={item['profileImage'] ? item['profileImage'] : alternate } onError={() => handleCollectionImageError(idx)} />
                            <Link to={`/home/singlecollection/${item['chain']}/${item['tokenAddress']}`} className="common-btn white">
                                <img src={bag} alt="" />
                                <img className="white" src={bagWhite} alt="" />
                                    Detail
                            </Link>
                        </div>

                        <div className="content">
                            <div className="bid-price">
                                <h3>{item['collectionName'] ? item['collectionName'] : ""}</h3>
                            </div>
                        </div>
                    </div>
                </Link> 
                )}                 
            </div>
             <div className="mt-4 text-center">
             {next < collections.length &&
                    <a className="common-btn border-white" onClick={handleMoreImage}>
                        Load More
                    </a>
             }
             </div>
             </>       
            }   
      </div>
    );
}

const mapDispatchToProps = { setLoader, getAllNfts, getAuctions, addRemoveFavItems, getAllCollection };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, chain, publicAddress, setAuctions, setAllNfts, collections } = Auth;
    return { isLogin, chain, publicAddress, setAuctions, setAllNfts, collections }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyCollection);

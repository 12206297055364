import axios  from "axios";
export const CreateIPFS = (fileImg) => {
    return new Promise(async (resolve, reject)=>{
            // const added = await client.add(data);
            // const uri = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
          const formData = new FormData();
          formData.append("file", fileImg);
          const resFile = await axios({
              method: "post",
              url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
              data: formData,
              headers: {
                  'Authorization': "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJiZWUwMTc3MC01YTMyLTQ2NWItOGRlNi0yYWQxNjM2OGQ1ZTQiLCJlbWFpbCI6ImRldmVsb3BtZW50QGVsaXR3ZWIzLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImlkIjoiRlJBMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfSx7ImlkIjoiTllDMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiI0Nzk2NTNjNjA3ODVmYWRiMThjZSIsInNjb3BlZEtleVNlY3JldCI6ImNlNzI3OTlkNGE2NWI3OGU1YjhhODU2MGEwZWRkOTU4NmI2MTNhN2NmY2JjOWFiOWI2YWMyMWM1YWUwNDg3MTEiLCJpYXQiOjE3MTc0MDEzNjV9.k89azoX-mwF0fqDYbB5NTAymP6lfXhVL5VR7o_5DHk0",
                  'pinata_api_key': `479653c60785fadb18ce`,
                  'pinata_secret_api_key': `ce72799d4a65b78e5b8a8560a0edd9586b613a7cfcbc9ab9b6ac21c5ae048711`,
                  "Content-Type": "multipart/form-data"
              },
          });
          const ImgHash = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`;
          return resolve(ImgHash);
    })
};

export const CreateIPFSMetadata = (data) => {
  return new Promise(async (resolve, reject)=>{
          // const added = await client.add(data);
          // const uri = `https://api.pinata.cloud/pinning/pinFileToIPFS`;

        const resFile = await axios({
            method: "post",
            url: "https://api.pinata.cloud/pinning/pinJSONToIPFS",
            data: data,
            headers: {
                'Authorization': "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJiZWUwMTc3MC01YTMyLTQ2NWItOGRlNi0yYWQxNjM2OGQ1ZTQiLCJlbWFpbCI6ImRldmVsb3BtZW50QGVsaXR3ZWIzLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImlkIjoiRlJBMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfSx7ImlkIjoiTllDMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiI0Nzk2NTNjNjA3ODVmYWRiMThjZSIsInNjb3BlZEtleVNlY3JldCI6ImNlNzI3OTlkNGE2NWI3OGU1YjhhODU2MGEwZWRkOTU4NmI2MTNhN2NmY2JjOWFiOWI2YWMyMWM1YWUwNDg3MTEiLCJpYXQiOjE3MTc0MDEzNjV9.k89azoX-mwF0fqDYbB5NTAymP6lfXhVL5VR7o_5DHk0",
                'pinata_api_key': `479653c60785fadb18ce`,
                'pinata_secret_api_key': `ce72799d4a65b78e5b8a8560a0edd9586b613a7cfcbc9ab9b6ac21c5ae048711`,
                'Content-Type': 'application/json', 
            },
        });
        const ImgHash = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`;
        return resolve(ImgHash);
  })
};
import React,{useEffect, useState} from 'react';
import { NavLink,Link, useNavigate,useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import {Form, Button} from 'react-bootstrap';
import {makeTokens,web3} from "../../store/contract";
import {setLoader,getSingleCollection,setSingleCollection , mintNFT721,mintNFT1155} from "../../store/actions/Auth";
import ProgressBar from "@ramonak/react-progress-bar";

import './index.css';
const collectionImage = "/images/collection-img.png";
const incrementicon = `/images/increment.png`;
const decrementicon = `/images/decrement.png`;
const back = "/images/arrow-left.png";

const MintCollection = (props) => {
    let [count, setCount] = useState(1);
    let [collection, setCollection] = useState();
    let [maxSupply, setMaxSupply] = useState(0);
    let [totalMint, setTotalMint] = useState(0);
    let [price, setPrice] = useState(0);
    let [token, setToken] = useState();
    let {chain,tokenAddress} = props.match.params;

    useEffect(()=>{
        props.setLoader({message:"Load minting engine",status:true});
        props.setSingleCollection({});
        props.getSingleCollection({chain,tokenAddress});
    },[tokenAddress]);

    const makeContract = async (contractAddress,type) => {
        let {ERC721ABI,ERC1155ABI} = await makeTokens();
        let Token = new web3.eth.Contract(type == "erc721" ? ERC721ABI : ERC1155ABI,contractAddress);
        if(type == "erc721" ) {
            let totalMint = await Token.methods.totalSupply().call();
            let maxSupply= await Token.methods.maxSupply().call();
            // let price = await Token.methods.priceRecipient().call();
            if(maxSupply) setMaxSupply(maxSupply);
            if(totalMint) setTotalMint(totalMint);
            // price = await web3.utils.fromWei(price.toString(),'ether');
            // setPrice(price);
            setToken(Token);
        }else if(type == "erc1155" ) {
            let totalMint = await Token.methods.totalSupply(1).call();
            let maxSupply= await Token.methods.maxSupply().call();
            // let price = await Token.methods.priceRecipient().call();
            if(maxSupply) setMaxSupply(maxSupply);
            if(totalMint) setTotalMint(totalMint);
            // price = await web3.utils.fromWei(price.toString(),'ether');
            // setPrice(price);
            setToken(Token);
        }
    }

    const mintNFT = async () => {
        try {
            if(props.singleCollection.tokenType == "erc721") {
                let nftIdPrevious =  await token.methods.walletOfOwner(props.publicAddress).call();
                props.setLoader({status:true, message:"Mint nft..."});
                let calculatePrice = Number(price) * Number(count)
                let wPrice = await web3.utils.toWei(calculatePrice.toString(), 'ether');
                web3.eth.sendTransaction({
                    from: props.publicAddress,
                    to: tokenAddress,
                    value: wPrice,
                    gas:5000000,
                    data: token.methods.mintNFT(props.publicAddress,count).encodeABI(),
                  }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                    .on('receipt', async receipt => {
                    let nftIdNew =  await token.methods.walletOfOwner(props.publicAddress).call();
                    let filteredArray = [];
                    if(nftIdPrevious.length > 0) filteredArray = nftIdNew.filter((item) => !nftIdPrevious.includes(item));
                    else filteredArray = nftIdNew;
                    makeContract(tokenAddress,props.singleCollection.tokenType);
                    props.mintNFT721({tokenAddress,count:filteredArray,price});
                    }).on('error', e => {
                        console.log("**************** error",e)
                      props.setLoader({status:false});
                      EventBus.publish('error', `Unable to Upload NFT ${e}`);
                });
            }
            else if(props.singleCollection.tokenType == "erc1155"){
                props.setLoader({status:true, message:"Mint nft..."});
                let calculatePrice = Number(price) * Number(count)
                let wPrice = await web3.utils.toWei(calculatePrice.toString(), 'ether');
                web3.eth.sendTransaction({
                    from: props.publicAddress,
                    to: tokenAddress,
                    value: wPrice,
                    gas:5000000,
                    data: token.methods.mintNFT(props.publicAddress,1,count).encodeABI(),
                  }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                    .on('receipt', async receipt => {
                    makeContract(tokenAddress,props.singleCollection.tokenType);
                    props.mintNFT1155({tokenAddress,mintAmount:count,price});
                    }).on('error', e => {
                        console.log("**************** error",e)
                      props.setLoader({status:false});
                      EventBus.publish('error', `Unable to Upload NFT ${e}`);
                });
            }
           } catch (error) {
                props.setLoader({status:false});
                return EventBus.publish('error', error);
           }
    }

    useEffect(()=>{
        if(Object.keys(props.singleCollection).length > 0){
            makeContract(props.singleCollection.tokenAddress,props.singleCollection.tokenType)
            setCollection(props.singleCollection);
        }
    },[props.singleCollection])

    async function copiedAddress() {
        EventBus.publish("success", "Collection Address Copied");
    }

    function incrementCount() {
        let totalcount = count + 1;
        if(totalcount > 5) return EventBus.publish("error", "You can mint 5 NFT");
        if(totalcount <= 0) setCount(1);
        else setCount(totalcount);
    }
    
    function decrementCount() {
        let totalcount = count - 1;
        if(totalcount <= 0) setCount(1);
        else setCount(totalcount);
    }

    function maxNFT(){
        totalMint = Number(totalMint);
        maxSupply = Number(maxSupply);
        if(maxSupply - totalMint > 5) setCount(5);
        else if(maxSupply - totalMint < 5 && maxSupply - totalMint > 0) setCount(maxSupply - totalMint);
        else if(maxSupply - totalMint <= 0) return EventBus.publish("error", "All Nft Minted"); 
    }
    return (
      <section className='item-detail minting-collection pt-0'> 
              <div className="d-flex justify-content-between mb-4">
                <h1 className="mb-0">{collection && collection.collectionName && collection['collectionName']}</h1>
                <Link class="common-btn border-white" to={`/home/singlecollection/${chain}/${tokenAddress}`}><img src={back} alt="" /> Back</Link>
              </div>
              <div className="row">
                  <div className="col-lg-6 ">
                      <div className="main-img-wrap">
                        {/* <img src={collectionImage} alt="" /> */}
                        <img src={collection && collection.profileImage ? collection['profileImage'] : collectionImage} alt="" />
                      </div>
                  </div>
                  <div className="col-lg-6 ps-xl-2">
                        <h1>“ {collection && collection.collectionName ? collection['collectionName'] :"-"} ”</h1>
                        <div className="item-interaction">
                            <div className="item-views">
                                <span>
                                {collection && collection.chain == 11155111 &&  "Ethereum" } 
                                {collection && collection.chain == 1 &&  "Ethereum" } 
                                {collection && collection.chain == 56 &&  "Binance" } 
                                {collection && collection.chain == 97 &&  "Binance" } 
                                {collection && collection.chain == 50 &&  "XDC" } 
                                {collection && collection.chain == 51 &&  "XDC" } 
                                {collection && collection.chain == 43114 &&  "AVAX" } 
                                {collection && collection.chain == 43113 &&  "AVAX" } 
                                </span>
                                <span>{collection && collection.tokenType ? collection['tokenType'].toUpperCase():"-"}</span>
                            </div>
                            {/* <div className="share-option">
                                <button><img src={share} alt="" /></button>
                                <button><img src={opetion} alt="" /></button>
                            </div> */}
                        </div>
                        <p>{collection && collection.collectionDesc ? collection['collectionDesc']:"-"}</p>

                        {/* <a className="see-more" href="#">See more</a> */}

                        <div className="progress-bar-wrap">
                            <div className="progressbar-head">
                                <span><b>{((Number(totalMint)/Number(maxSupply))*100).toFixed(2)} %</b> Minted</span>

                                <span>{totalMint}/{maxSupply}</span>                       
                            </div>
                            <ProgressBar className="progress-bar" completed={(Number(totalMint)/Number(maxSupply))*100} maxCompleted={100} />
                        </div>

                        <div className="public-stage-wrapper">
                            <h3>
                                Public Stage                 
                            </h3>

                            <Form className="inner">
                                <div className="limit-wrap">
                                    <div className="info">
                                        <p>

                                            {collection && collection.chain == 11155111 &&  `${price} ETH` } 
                                            {collection && collection.chain == 1 &&  `${price} ETH` } 
                                            {collection && collection.chain == 56 &&  `${price} BNB` } 
                                            {collection && collection.chain == 97 &&  `${price} BNB` } 
                                            {collection && collection.chain == 50 &&  `${price} XDC` } 
                                            {collection && collection.chain == 51 &&  `${price} XDC` } 
                                            {collection && collection.chain == 43114 &&  `${price} AVAX` } 
                                            {collection && collection.chain == 43113 &&  `${price} AVAX` } 

                                        </p>

                                        <span>Limit 5 per wallet</span>
                                    </div>

                                    <Form.Group className="form-group">
                                        <img className="increment" onClick={decrementCount} src={decrementicon} alt="" />

                                        <Form.Control type="number" value={count} />

                                        <img  className="decrement" onClick={incrementCount} src={incrementicon} alt="" />
                                    </Form.Group>

                                    <a className="common-btn white" onClick={maxNFT}>Max</a>
                                </div>

                                <a className="common-btn" onClick={mintNFT}>Mint</a>
                            </Form>
                        </div>
                    </div>
              </div>
      </section>
    );
  }

const mapDispatchToProps = { setLoader, getSingleCollection,setSingleCollection, mintNFT721,mintNFT1155 };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, chain, publicAddress, singleCollection} = Auth;
    return { isLogin, chain, publicAddress, singleCollection }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MintCollection));
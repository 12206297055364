import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { Tabs, Tab } from 'react-bootstrap';
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import { Form, Button } from 'react-bootstrap';
import { makeTokens, web3 } from "../../store/contract";
import { setLoader, createCollection, createCategory } from "../../store/actions/Auth";
import { ApiUrl } from "../../store/config";
const alternate = "/images/activity-img.png";

const SingleCollection = (props) => {
    let [profile, setProfile] = useState();
    let [background, setBackground] = useState();
    let [name, setName] = useState();
    let [url, setUrl] = useState();
    let [desc, setDesc] = useState();
    let [website, setWebsite] = useState();
    let [discord, setDiscord] = useState();
    let [instagram, setInstagram] = useState();
    let [medium, setMedium] = useState();
    let [telegram, setTelegram] = useState();
    let [royality, setRoyality] = useState("");
    let [royalityAddress, setRoyalityAddress] = useState("");
    let [collectionSymbol, setCollectionSymbol] = useState();
    let [totalSypply, setTotalSypply] = useState();
    let [collectionType, setCollectionType] = useState("championship nfts");
    let [collectionPrice, setCollectionPrice] = useState();
    let [category, setCategory] = useState();
    let [logoPreview, setLogoPreview] = useState();
    let [backgroundPreview, setBackgroundPreview] = useState();

    const handleOnWheel = (event) => {
        const { type } = event.target;
        if(type === 'number'){
          event.preventDefault();
        }
      }
      
      useEffect(() => {
        document.addEventListener('wheel', handleOnWheel, { passive: false });
    
        return () => {
          document.removeEventListener('wheel', handleOnWheel);
        };
      }, []);

    const changeRoyality = async (e) => {
        setRoyality();
        let percentage = parseInt(e.target.value);
        if (percentage < 1) {
            setRoyality(1)
            return EventBus.publish('error', `Minimum royality percentage is 1`);
        } else if (percentage > 5) {
            setRoyality(5)
            return EventBus.publish('error', `Maximum royality percentage is 5`);
        } else if (percentage >= 1 || percentage <= 5) {
            setRoyality(percentage)
        }
    }

    async function getIPFSDataWithPinata(url) {
        return new Promise(async (resolve, reject) => {
            try {
                let data = await fetch(`${ApiUrl}/nft/validateURI`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
                    },
                    body: JSON.stringify({ url }), // Convert your data to JSON format if needed
                });
                if (parseInt(data.status) == 200) {
                    return resolve(true);
                }
                else return resolve(false);
            } catch (e) {
                return resolve(false);
            }
        });
    }

    const getIPFSData = (Uri) => {
        return new Promise(async (resolve, reject) => {
            try {
                let data = await fetch(`${Uri}1`);
                if (parseInt(data.status) == 200) {
                    data = await data.json();
                    return resolve(true);
                }
                if (parseInt(data.status) == 404) {
                    data = await fetch(`${Uri}1.json`);
                    if (parseInt(data.status) == 200) {
                        return resolve(true);
                    } else {
                        return resolve(false);
                    }
                }
            } catch (e) {
                return resolve(false);
            }
        });
    }

    function isValidEthereumAddress(address) {
        // Ethereum address pattern
        const ethereumAddressPattern = /^(0x)?[0-9a-fA-F]{40}$/;
      
        // Check if the address matches the pattern
        return ethereumAddressPattern.test(address);
    }

    const createNFT = async () => {
        try {

            if (!profile) return EventBus.publish('error', `Please upload logo image`);
            if (profile == undefined) return EventBus.publish("error", `Please upload logo image`);
            if (!background) return EventBus.publish('error', `Please upload background image`);
            if (background == undefined) return EventBus.publish("error", `Please upload background image`);
            if (!name) return EventBus.publish('error', `Please add name`);
            if (!name.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter collection name`);
                return;
            }
            if (!name.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Collection name must contain alphabets`);
                return;
            }
            if (!collectionSymbol) return EventBus.publish('error', `Please add symbol`);
            if (!collectionSymbol.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter collection symbol`);
                return;
            }
            if (!collectionSymbol.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Collection symbol must contain alphabets`);
                return;
            }
            if (!totalSypply) return EventBus.publish('error', `Please add totalSypply`);
            if (!desc) return EventBus.publish('error', `Please add description`);
            if (!desc.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter collection description`);
                return;
            }
            if (!desc.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Collection description must contain alphabets`);
                return;
            }
            if (!collectionType) return EventBus.publish('error', `Please add Category`);

            if (!royalityAddress) return EventBus.publish('error', "Please set valid royality address");
            const isValid = isValidEthereumAddress(royalityAddress);
            if (!isValid) return EventBus.publish('error', "Please set valid royality address");
            if (!royality) return EventBus.publish('error', "Please set royality percentage");

            // if (!collectionPrice) return EventBus.publish('error', `Please set collections price`);
            // if (collectionPrice < 0) return EventBus.publish('error', "Collection price cannot be a negative value");
            if (!url) return EventBus.publish('error', `Please add base uri`);
            if (url && !url.endsWith("/")) url = url + "/";
            let checkUrl;
            if (url.split("/")[2] == 'gateway.pinata.cloud') {
                checkUrl = await getIPFSDataWithPinata(url);
            } else {
                checkUrl = await getIPFSData(url);
            }
            if (checkUrl == false) return EventBus.publish('error', `Invalid url`);

            props.setLoader({ message: 'Collections deploying...', status: true });

            let { ERC721ABI, ERC721BYTECODE, MarketplaceAddress } = await makeTokens();

            let from = (await web3.currentProvider.enable())[0];
            let contract = new web3.eth.Contract(ERC721ABI);
            let deploy = await contract.deploy({
                data: ERC721BYTECODE,
                arguments: [name, collectionSymbol, url, royality, "0xd4f3d80b522c9887a126c35a5b50fc69b14476a7","0xb5f3F8b0F092fa20f98F6b78F05688C120Ae778A",royalityAddress,0, totalSypply],
                gas: 5000000,
            });

            await deploy
                .send({ from })
                .on("transactionHash", (hash) =>
                    console.log(`************** deploy contract hash = ${hash}`)
                )
                .on("receipt", async (receipt) => {
                    const formData = new FormData();
                    formData.append('profile', profile);
                    formData.append('background', background);
                    formData.append('collectionName', name);
                    formData.append('baseUri', url);
                    formData.append('collectionDesc', desc);
                    formData.append('discord', discord);
                    formData.append('instagram', instagram);
                    formData.append('telegram', telegram);
                    formData.append('royality', royality);
                    formData.append('website', website);
                    formData.append('medium', medium);
                    formData.append('collectionSymbol', collectionSymbol);
                    formData.append('totalSypply', totalSypply);
                    formData.append('collectionType', collectionType);
                    formData.append('tokenType', "erc721");
                    formData.append('tokenAddress', receipt['contractAddress']);
                    formData.append('collectionPrice', 0);
                    formData.append('chain', props.chain);
                    props.createCollection(formData);
                    // props.setLoader({ message: 'Collections Deploying Please Wait...', status: false });
                })
        } catch (error) {
            props.setLoader({ status: false });
            return EventBus.publish('error', error.message);
        }
    }

    const submitCategory = async () => {
        if(!category) return EventBus.publish('error', `Please set category`);
        props.setLoader({ message: 'Category creating...', status: true });
        props.createCategory({category})
    }

    const uploadLogo = async (e) => {
        let files = e.target.files[0].type;
        if (files.split('/')[0] !== 'image') return EventBus.publish('error', `Please upload image only in jpeg, png, gif`);
        let reader = new FileReader();
        reader.onload = async (event) => {
            setLogoPreview(event.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
        setProfile(e.target.files[0])
    }

    const uploadBackground = async (e) => {
        let files = e.target.files[0].type;
        if (files.split('/')[0] !== 'image') return EventBus.publish('error', `Please upload image only in jpeg, png, gif`);
        let reader = new FileReader();
        reader.onload = async (event) => {
            setBackgroundPreview(event.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
        setBackground(e.target.files[0])
    }

    return (
        <>
            <h2>Logo image</h2>

            <p>This image will also be used for navigation. 350 x 350 recommended.</p>

            <Form.Group className="form-group" controlId="formBasicFame">

                <div className="upload-file">
                    <div>
                        <img className="bg-image" src={logoPreview? logoPreview: alternate} alt="" />
                        <span>{profile ? (profile['name'] && profile['name'].substring(0, 30) + '...') : "Drag and drop file here"}</span>
                    </div>
                    <Form.Control type="file" onChange={e => uploadLogo(e)} accept=".png, .jpg, .jpeg, .gif" 
                    />

                    <button className="common-btn border-white">Choose File</button>
                </div>

                <span>Files supported: PNG, JPEG or JPG. Max 200mb.</span>
            </Form.Group>

            <h2>Banner image</h2>

            <p>This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices. 1400 x 400 recommended.</p>

            <Form.Group className="form-group" controlId="formBasicFame">

                <div className="upload-file">
                    <div>
                        <img className="bg-image" src={backgroundPreview? backgroundPreview: alternate} alt="" />
                        <span>{background ? (background['name'] && background['name'].substring(0, 30) + '...') : "Drag and drop file here" }</span>
                    </div>
                    <Form.Control type="file" onChange={e => uploadBackground(e)} accept=".png, .jpg, .jpeg, .gif" 
                    />

                    <button className="common-btn border-white">Choose File</button>
                </div>

                <span>Files supported: PNG, JPEG or JPG. Max 200mb.</span>
            </Form.Group>

            <Form.Group className="form-group" controlId="Name">
                <label>Name</label>
                <Form.Control type="text" placeholder="Item Example “ MARS Stones”" onChange={e => setName(e.target.value)} maxLength={20} />
            </Form.Group>

            <Form.Group className="form-group" controlId="Name">
                <label>Symbol</label>
                <Form.Control type="text" placeholder="Item Example “ MARS Stones”" onChange={e => setCollectionSymbol(e.target.value)} maxLength={20} />
            </Form.Group>

            <Form.Group className="form-group" controlId="Name">
                <label>Total Supply</label>
                <Form.Control onWheel={handleOnWheel} min="0" type="number" placeholder="1000000" onChange={e => setTotalSypply(e.target.value)} />
            </Form.Group>

            <Form.Group className="form-group" controlId="URL">
                <label>Base URI</label>
                <span><a href="https://tool.cifiapp.com" target='blank' > Create Base Uri</a></span>
                <p>Example: https://example.com/</p>
                <Form.Control type="url" placeholder="https://marketplace.com/collection/mars-stones" onChange={e => {
                    let inputValue = e.target.value;
                    if (inputValue && !inputValue.startsWith('https://')) {
                        inputValue = 'https://' + inputValue;
                        setUrl(inputValue)
                    } else {
                        setUrl(e.target.value)
                    }
                }
                } value={url} />
            </Form.Group>

            <Form.Group className="form-group" controlId="Desc">
                <label>Description</label>
                <p>Markdown text is supported. 0 of 1000 characters used.</p>
                <Form.Control as="textarea" onChange={e => setDesc(e.target.value)} maxLength={1000} />
            </Form.Group>

            <Form.Group className="form-group" controlId="Category">
                <label>Category</label>
                <p>Adding a category will help make your item discoverable on Marketplace.</p>
                <Form.Select className="mb-3" controlId="formBasicSubject" onChange={e => setCollectionType(e.target.value)} value={collectionType}>
                    {props.category && props.category.length > 0 && props.category.map(item=>
                        <option value={item['category']}>{item['category']}</option>
                    )}
                    {/* <option value="abstraction">Abstraction</option>
                    <option value="art">Art</option>
                    <option value="music">Music</option>
                    <option value="domain name">Domain Names</option>
                    <option value="virtual world">Virtual World</option>
                    <option value="trading cards">Trading Cards</option>
                    <option value="sports">Sports</option>
                    <option value="utility">Utility</option> */}
                    {/* <option value="championship nfts">Championship NFTs</option>
                    <option value="trading cards">Trading Cards</option>
                    <option value="partners">Partners</option> */}
                </Form.Select>
                {/* <Button className="common-btn border-white">Add category</Button> */}
            </Form.Group>

            <Form.Group className="form-group add-category" controlId="AddCategory">
                <Form.Control type="text" placeholder="Add Custom Category" onChange={e=>setCategory(e.target.value)}/>
                <Button className="common-btn create-btn" onClick={submitCategory}>Add</Button>
            </Form.Group>
            
            <Form.Group className="form-group" controlId="Links">
                <label>Links</label>
                <Form.Control className="mb-3" type="text" placeholder="Website" onChange={e => setWebsite(e.target.value)} />
                <Form.Control className="mb-3" type="text" placeholder="Discord id" onChange={e => setDiscord(e.target.value)} />
                <Form.Control className="mb-3" type="text" placeholder="Instagram handle" onChange={e => setInstagram(e.target.value)} />
                <Form.Control className="mb-3" type="text" placeholder="Medium handle" onChange={e => setMedium(e.target.value)} />
                <Form.Control className="mb-3" type="text" placeholder="Telegram id" onChange={e => setTelegram(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicName">
                <label>Royalty address</label>
                <Form.Control type="text" placeholder="Add royalty address" onChange={e=>setRoyalityAddress(e.target.value)} value={royalityAddress} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicName">
                <label>Royalty percentage</label>
                <Form.Control type="number" onWheel={handleOnWheel} min="0" placeholder="Add royalty percentage" onChange={e=>setRoyality(parseInt(e.target.value))} value={royality} />
            </Form.Group>
            {/* <Form.Group className="form-group" controlId="Royalties">
                <label>Price</label>
                <Form.Control type="number" placeholder="Price (example 0.2 eth)" onChange={e => setCollectionPrice(e.target.value)} />
            </Form.Group> */}

            <Button className="common-btn create-btn" onClick={() => createNFT()}>Create</Button>


        </>
    );
};

const mapDispatchToProps = { setLoader, createCollection, createCategory };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, chain, publicAddress, category } = Auth;
    return { isLogin, chain, publicAddress, category }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleCollection);
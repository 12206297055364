
import React, { useEffect, useState } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Button } from 'react-bootstrap';
import SingleCollection from './SingleCollection';
import MultipleCollections from './MultipleCollections';
import { setLoader, getCollection, getUser, getUserNft, getUserFavItems, addRemoveFavItems, getCategory } from "../../store/actions/Auth";

import './index.css';
const back = "/images/arrow-left.png";
const collectionImage = "/images/collection-img.png";
const bag = "/images/bag.png";
const bagWhite = "/images/bag-white.png";

const CreateCollection = (props) => {

    let [selectCollection, setSelectCollection] = useState("Single");

    useEffect(()=>{
      props.getCategory()
    },[])

    return (
      <div className="pb-5">
        <div className="d-flex justify-content-between mb-4">
          <h1 className="mb-0">Create</h1>
          {/* <Link class="common-btn border-white" to="createitem">Create Single NFT</Link> */}
        </div>
      <div className="signin full">
                                <Form className="create">
                                <div className="d-flex justify-content-between mb-5 align-items-center">
                                    <p className='mb-0'>Choose "Single" for one of a kind or "Multiple" if you want to sell one collectible multiple times</p>
                                         <Link class="common-btn" to="createitem">Create Single NFT</Link>
                                         </div>
                                    <div className="choose">
                                        <div className={`box ${selectCollection == "Single" && "active"}`} onClick={() => setSelectCollection("Single")}>
                                            <img src="./images/collection-img.png" alt="" />

                                            <div className="info">
                                                <h3>ERC721</h3>

                                                <p>If you want to highlight the uniqueness and individuality of your item</p>
                                            </div>
                                        </div>

                                        <div className={`box ${selectCollection == "Multiple" && "active"}`} onClick={() => setSelectCollection("Multiple")}>
                                            <img src="./images/collection-img.png" alt="" />

                                            <div className="info">
                                                <h3>ERC1155</h3>

                                                <p>If you want to share your item with a large number of community members</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='half'>
                                      {selectCollection == "Single" && <SingleCollection />}
                                      {selectCollection == "Multiple" && <MultipleCollections />}
                                    </div>
                                    
                                </Form>
      </div>
      </div>
    );
}

const mapDispatchToProps = { setLoader, getCollection, getUser, getUserNft, getUserFavItems, addRemoveFavItems, getCategory };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, chain, publicAddress, userCollections, userData, userNfts, userFavitems } = Auth;
    return { isLogin, chain, publicAddress, userCollections, userData, userNfts, userFavitems }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCollection);
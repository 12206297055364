import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, Link } from "react-router-dom";
import {Tabs, Tab, Form, Modal, Button} from 'react-bootstrap';
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import { CreateIPFS, CreateIPFSMetadata } from "../../store/ipfs";
import { setLoader, createNFT } from "../../store/actions/Auth";
import { makeTokens, web3 } from "../../store/contract";

import './index.css';
const back = "/images/arrow-left.png";
const collectionImage = "/images/collection-img.png";
const incrementicon = `/images/increment.png`;

const CreateItem = (props) => {
    const [preview, setPreview] = useState("");
    const [method, setMethod] = useState("fixed");
    const [file, setFile] = useState("");
    const [collection, setCollection] = useState("championship nfts");
    const [price, setPrice] = useState("");
    const [royality, setRoyality] = useState("");
    const [royalityAddress, setRoyalityAddress] = useState("");
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [allproperties, setAllProperties] = useState([]);

    const [propertiesModal, setPropertiesModal] = useState(false);
    const handleClose = () => setPropertiesModal(false);

    const [objects, setObjects] = useState([{}]);
    
    const [newKey, setNewKey] = useState('');
    const [newValue, setNewValue] = useState('');
    
    const handleOnWheel = (event) => {
        const { type } = event.target;
        if(type === 'number'){
          event.preventDefault();
        }
      }
      
      useEffect(() => {
        document.addEventListener('wheel', handleOnWheel, { passive: false });
    
        return () => {
          document.removeEventListener('wheel', handleOnWheel);
        };
      }, []);
      
    const handleAddProperty = () => {
        // Create a new array with updated objects
        const updatedArray = objects.map(obj => ({
          ...obj,
          [newKey]: newValue,
        }));
    
        // Update state with the new array
        setObjects(updatedArray);
    
        // Clear input fields
        setNewKey('');
        setNewValue('');
        setAllProperties(updatedArray)
    };
    
    const handleRemoveProperty = (propertyKey) => {
        // Create a new array with properties removed
        const updatedArray = objects.map(obj => {
          const { [propertyKey]: propertyToRemove, ...rest } = obj;
          return rest;
        });
    
        // Update state with the new array
        setObjects(updatedArray);
        setAllProperties(updatedArray)
    };

      
    const addPropertiesNft = async (e) => {
        handleClose()
    }

    const uploadImage = async (e) => {
        if (e.target.files.length == 0) return;
        let files = e.target.files[0].type;
        if (files.split('/')[0] !== 'image') return EventBus.publish('error', `Please upload image only in jpeg, png, gif`);
        let reader = new FileReader();
        reader.onload = async (event) => {
            setPreview(event.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
        setFile(e.target.files[0]);
    };

    function isValidEthereumAddress(address) {
        // Ethereum address pattern
        const ethereumAddressPattern = /^(0x)?[0-9a-fA-F]{40}$/;
      
        // Check if the address matches the pattern
        return ethereumAddressPattern.test(address);
    }
      
    const submit = async (e) => {
        try {
            e.preventDefault();
            if (!file) return EventBus.publish('error', "Please upload nft");
            if (file == undefined) return EventBus.publish("error", `Please upload nft`);
            if (!price) return EventBus.publish('error', "Please set price");
            if (price < 0) return EventBus.publish('error', "Price cannot be a negative value");
            if (!royalityAddress) return EventBus.publish('error', "Please set valid royality address");
            const isValid = isValidEthereumAddress(royalityAddress);
            if (!isValid) return EventBus.publish('error', "Please set valid royality address");
            if (!royality) return EventBus.publish('error', "Please set royality percentage");

            if (!title) return EventBus.publish('error', "Please set title");
            if (!title.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter title`);
                return;
            }
            if (!title.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Title must contain alphabets`);
                return;
            }
            if (!desc) return EventBus.publish('error', "Please set Description");
            if (!desc.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter Description`);
                return;
            }
            if (!desc.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Description must contain alphabets`);
                return;
            }

            props.setLoader({ status: true, message: "Nft creating..." });
            let url = await CreateIPFS(file);
            let metadata = await CreateIPFSMetadata(JSON.stringify({ image: url, title: title, description: desc, collection: collection, attributes: objects}));
            props.setLoader({ status: true, message: "Uploading nft..." });
            let { TokenAddress, Token } = await makeTokens();
            let wPrice = await web3.utils.toWei(price.toString(), 'ether');
            web3.eth.sendTransaction({
                from: props.publicAddress,
                to: TokenAddress,
                value: 0,
                gas: 5000000,
                data: Token.methods.createToken(metadata, wPrice, royality, royalityAddress,"0xd4f3d80b522c9887a126c35a5b50fc69b14476a7","0xb5f3F8b0F092fa20f98F6b78F05688C120Ae778A").encodeABI(),
            }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                .on('receipt', async receipt => {
                    let nftId = await Token.methods.walletOfOwner(props.publicAddress).call();
                    nftId = nftId[nftId.length - 1];
                    props.createNFT({ attributes: objects, tokenAddress: TokenAddress, nftId, price, title: title, desc: desc, image: url, metadataUri: metadata, chain: props.chain, collectionType: collection })
                    setFile("")
                    setCollection("abstraction")
                    setPreview("")
                    setPrice("")
                    setRoyality("")
                    setTitle("")
                    setDesc("")
                    setObjects([{}])
                    setAllProperties([])

                }).on('error', e => {
                    props.setLoader({ status: false });
                    EventBus.publish('error', `Unable to Upload NFT ${e}`);
                });
        } catch (error) {
            props.setLoader({ status: false });
            return EventBus.publish('error', error);
        }
    };

    return (
      <section className="create-item-wrapper picks pt-0">
        <div className="d-flex justify-content-between mb-4">
          <h1 className="mb-0">Create</h1>
          <Link class="common-btn" to="createcollection"><img src={back} alt="" /> Back</Link>
        </div>

          <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-5">
                            <h3>Preview item</h3>

                            <div className="collection-box">
                                <div className="img-wrap">
                                    <img src={preview ? preview : "./images/collection-img.png"} alt="Preview" />
                                </div>

                                <div className="content">
                                    <div className="collection-content-head">
                                        <h3>{title}</h3>
                                        <div className="protocol">
                                            {
                                                props.chain == "97" || props.chain == "56" ? "BSC" :
                                                    props.chain == "1" || props.chain == "11155111" ? "ETH" :
                                                        props.chain == "43114" || props.chain == "43113" ? "AVAX" :
                                                            props.chain == "51" || props.chain == "50" ? "XDC" : "-"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {allproperties.length > 0 &&
                            <div>
                                NFT Properties
                                {allproperties.length > 0 && objects.map(obj => 
                                        Object.entries(obj).map(([key, value]) =>
                                            <p key={key}>
                                                {key}: {value}{' '}
                                            </p>
                                        )
                                )}
                            </div>
                            }
          </div>
            <div className="col-xl-9 col-lg-8 col-md-7">
                    <div className="signin">
                        <Form onSubmit={submit}>
                            <Form.Group className="mb-3" controlId="formBasicFame">
                                            <label>Upload file</label>

                                            <div className="upload-file">
                                                <span>PNG, JPG, GIF . Max 200mb.</span>

                                                <Form.Control type="file" onChange={uploadImage} accept=".png, .jpg, .jpeg, .gif" />

                                                <button className="common-btn border-white">Upload File</button>
                                            </div>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicName">
                                            <label>Price</label>
                                            <Form.Control onWheel={handleOnWheel} min="0" type="number" placeholder="Enter price for one item (ETH)" onChange={e => setPrice(e.target.value)} value={price} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicName">
                                            <label>Title</label>
                                            <Form.Control type="text" placeholder="Item Name" onChange={e => setTitle(e.target.value)} value={title} maxLength={20} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicTextarea">
                                            <label>Description</label>
                                            <Form.Control as="textarea" placeholder="e.g. “This is very limited item”" onChange={e => setDesc(e.target.value)} value={desc} maxLength={300} />
                            </Form.Group>

                            <div className="row">
                                            <div className="col-lg-4">
                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                <label>Royalty address</label>
                                                <Form.Control type="text" placeholder="Add royalty address" onChange={e=>setRoyalityAddress(e.target.value)} value={royalityAddress} />
                                            </Form.Group>
                                            </div>

                                            <div className="col-lg-4">
                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                <label>Royalty percentage</label>
                                                <Form.Control onWheel={handleOnWheel} min="0" type="number" min="0" placeholder="Add royalty percentage" onChange={e=>setRoyality(parseInt(e.target.value))} value={royality} />
                                            </Form.Group>
                                            </div>

                                            <div className="col-lg-4">
                                                <Form.Group className="mb-3" controlId="formBasicName">
                                                    <label>Collection</label>
                                                    <Form.Select className="mb-3" controlId="formBasicSubject" onChange={e => setCollection(e.target.value)} value={collection}>
                                                        <option value="championship nfts">Championship NFTs</option>
                                                        <option value="trading cards">Trading Cards</option>
                                                        <option value="partners">Partners</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                            </div>
                            <div className="btn-wrap">
                                <Button type='submit' className="create-btn"> Create Item </Button>
                                <Button className="create-btn" onClick={()=>setPropertiesModal(true)}> Add NFT Properties </Button>
                            </div>
                        </Form>
                    </div>

            </div>
          </div>

          <Modal className="common-modal signin" show={propertiesModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    Add NFT Properties

                    <button type="button" class="btn-close" aria-label="Close" fdprocessedid="v7vaaa"></button>
                </Modal.Header>
                                            
                <div className='signin'>
                    <ul>
                        {objects.map(obj => (
                        <li key={obj.id}>
                            {/* {obj.name} */}
                            {Object.entries(obj).map(([key, value]) => (
                            <p key={key}>
                                {key}: {value}{' '}
                                <button className="common-btn mt-2" onClick={() => handleRemoveProperty(key)}>Remove</button>
                            </p>
                            ))}
                        </li>
                        ))}
                    </ul>

                    <div>
                        <label>
                        New Key:
                        <input type="text" value={newKey} onChange={e => setNewKey(e.target.value)} />
                        </label>
                        <label>
                        New Value:
                        <input type="text" value={newValue} onChange={e => setNewValue(e.target.value)} />
                        </label>
                        <button className="common-btn" onClick={handleAddProperty}>Add Property</button>
                        <button className="common-btn" onClick={addPropertiesNft}>Submit</button>
                    </div>
                </div>
            </Modal>
      </section>
 );
};

const mapDispatchToProps = { setLoader, createNFT };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, network, chain, publicAddress } = Auth;
    return { isLogin, network, chain, publicAddress }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateItem);

